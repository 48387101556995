import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"
import { Box } from "./Box";
import { FixedSizeList } from 'react-window';

const PortfolioVirtListFiltered = (props) => {
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('createDate');
  const selectedSort = useRef();
  const _rowifySortedFilteredPortfolios = (portfolios) => {
    let screenSize = window.innerWidth;
    var rowSize = 0;
    if (screenSize < 576) {
      // xsmall - one portfolio per row will be rendered
      rowSize = 1;
    } else if (screenSize >= 576 && screenSize < 768) {
      // small - 2 portfolios per row will be rendered
      rowSize = 2;
    } else if (screenSize >= 768 && screenSize < 992) {
      // medium - 3 portfolios per row will be rendered
      rowSize = 3;
    } else if (screenSize >= 992 && screenSize < 1200) {
      // large - 4 portfolios per row will be rendered
      rowSize = 4;
    } else if (screenSize >= 1200 && screenSize < 1400) {
      // x-large - 5 portfolios per row will be rendered
      rowSize = 4;
    } else if (screenSize >= 1400 && screenSize < 1600) {
      // x-large - 5 portfolios per row will be rendered
      rowSize = 4;
    } else {
      // xx-large - 6 portfolios per row will be rendered
      rowSize = 6;
    }
    console.log(`_rowifySortedFilteredPortfolios screenSize: ${screenSize} rowSize: ${rowSize}`);

    var rowsOfRows = [];
    var row = [];
    for (let x = 0; x < portfolios.length; x++) {
      if (row.length === rowSize) {
        rowsOfRows.push(row);
        row = [];
      }
      row.push(<Box key={portfolios[x].id} data={portfolios[x]} portfolioClickHook={props.portfolioClickHook} portfolioDeleteHook={props.portfolioDeleteHook}></Box>);
    }
    if (row.length > 0) {
      rowsOfRows.push(row);
    }
    return rowsOfRows;
  }
  const [sortedFilteredPortfolios, _setSortedFilteredPortfolios] = useState(_rowifySortedFilteredPortfolios(props.portfolios));

  const handleSetSort = (e) => {
    setSort(selectedSort.current.value);
    var newP = JSON.parse(JSON.stringify(props.portfolios));
    if (sort === "type") {
      newP.sort((a, b) => {
        if (a.type === "gofolio") {
          if (b.type === "userDefined") {
            return -1
          } else {
            return 0
          }
        } else {
          // a.type == "userDefined"
          if (b.type === "gofolio") {
            return 1
          } else {
            return 0
          }
        }
      });
    } else if (props.sort === "createDateDesc") {
      // newest-oldest
      newP.sort((a, b) => {
        let da = new Date(a.createDate),
          db = new Date(b.createDate);
        return db - da;
      });
    } else if (props.sort === "createDateAsc") {
      // oldest-newest
      newP.sort((a, b) => {
        let da = new Date(a.createDate),
          db = new Date(b.createDate);
        return da - db;
      });
    } else if (props.sort === "alpha") {
      newP.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        return 1;
      });
    }
    handleSetSortedFilteredPortfolios(newP);
  };
  const handleSetFilter = (filter) => {
    setFilter(filter);
    var newP = JSON.parse(JSON.stringify(props.portfolios));
    newP.filter((portfolio) => {
      if (props.filter === "all" || (props.filter === "gofolio" && portfolio.type === "gofolio") || (props.filter === "userDefined" && portfolio.type === "userDefined")) {
        return true;
      }
      return false;
    });
    handleSetSortedFilteredPortfolios(newP);
  }
  const handleSetSortedFilteredPortfolios = (portfolios) => {
    _setSortedFilteredPortfolios(_rowifySortedFilteredPortfolios(portfolios));
  }

  return (
    <>
      <div className="top-bar">
        <ul>
          <li><Link className={`${filter === "gofolio" ? "active" : ""}`} onClick={() => handleSetFilter('gofolio')}>GoFolio Calculated</Link></li>
          <li><Link className={`${filter === "userDefined" ? "active" : ""}`} onClick={() => handleSetFilter('userDefined')}>User Defined</Link></li>
          <li><Link className={`${filter === "all" ? "active" : ""}`} onClick={() => handleSetFilter('all')}>Show All</Link></li>
          <li className="ms-auto">
            <div className="d-flex sort-by align-items-center">
              <p className="my-auto">Sort by:</p>
              <span>
                <select className="form-select form-select-md" aria-label=".form-select-md example" ref={selectedSort} onChange={handleSetSort}>
                  <option value="createDateDesc" className={`${sort === "createDateDesc" ? "selected" : ""}`}>Create Date (newest-oldest)</option>
                  <option value="createDateAsc" className={`${sort === "createDateAsc" ? "selected" : ""}`}>Create Date (oldest-newest)</option>
                  <option value="type" className={`${sort === "type" ? "selected" : ""}`}>Portflolio type</option>
                  <option value="alpha" className={`${sort === "type" ? "alpha" : ""}`}>Alphabetical</option>
                </select>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col" align="center">
          <PortfolioVirtGrid portfolios={sortedFilteredPortfolios} portfolioClickHook={props.portfolioClickHook} portfolioDeleteHook={props.portfolioDeleteHook}></PortfolioVirtGrid>
        </div>
      </div>
    </>
  )
}
function PortfolioVirtGrid(props) {
  const PortfolioVirtRowItem = (props) => {
    const { data, index, style } = props;
    return <div style={style}><div className="row">{data.rowData[index]}</div></div>
  }
  return (
    <FixedSizeList
      height={350}
      width={1000}
      itemCount={props.portfolios.length}
      itemSize={165}
      itemData={{
        rowData: props.portfolios,
        portfolioClickHook: props.portfolioClickHook,
        portfolioDeleteHook: props.portfolioDeleteHook,
      }}
    >
      {PortfolioVirtRowItem}
    </FixedSizeList>
  )
}

export { PortfolioVirtListFiltered }