import React, { useEffect } from 'react';
import AccountTopbar from '../account/Header'
import EmailSettings from '../account/EmailSettings'
import AccountNav from '../account/Nav';

const MyAccount = () => {
  useEffect(() => {
    const fullHeight = () => {
      const elements = document.querySelectorAll('.js-fullheight');
      elements.forEach((element) => {
        element.style.height = window.innerHeight + 'px';
      });
    };

    const handleResize = () => {
      fullHeight();
    };

    window.addEventListener('resize', handleResize);

    const sidebarToggle = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('active');
    };

    const sidebarCollapse = document.getElementById('sidebarCollapse');
    sidebarCollapse.addEventListener('click', sidebarToggle);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      sidebarCollapse.removeEventListener('click', sidebarToggle);
    };
  }, []);
  return (
    <>
      <AccountNav />
      <AccountTopbar />
      <EmailSettings />
    </>
  )
}

export default MyAccount