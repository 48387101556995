import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './fonts/Outfit-VariableFont_wght.ttf';
import './App.css'
import Pricing from './components/view/Pricing';
import Login from './components/view/Login';
import Home from './components/view/Home';
import Blogs from './components/view/Blogs';
import FAQ from './components/view/FAQ';
import Dashboard from './components/view/Dashboard';


function App() {

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/blog" element={<Blogs page={'blog'} />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/pricing" element={<Pricing page={'pricing'} />} />
        <Route exact path="/faq" element={<FAQ page={'faq'} />} />
        <Route exact path="/app" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
