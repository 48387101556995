import React, { useState } from "react";
import { Chart } from "./Chart";
import { InfoTip, PortfolioValue, PortfolioReturn } from '../common/InfoTip';
import { formatDate, getResultKeyToRender, getReturn, getBeta, getStandardDeviation, formatPrincipalValueResult } from "../common/methods";

const CompareResult = (props) => {
    const [resultSelection, setResultSelection] = useState(props.data.type === "gofolio" ? 'riskAdjustedReturn' : 'userDefined');
    const [resultSelectionInterval, setResultSelectionInterval] = useState('1Y');
    const [principalValue, setPrincipalValue] = useState(10000);

    const handleSetResultSelectionInterval = (data) => {
        setResultSelectionInterval(data.target.value);
    };
    const handleSetResultSelection = (data) => {
        setResultSelection(data.target.value);
    };
    const handleSetPrincipalValue = (data) => {
        let val = parseInt(data.target.value);
        if (isNaN(val)) {
            setPrincipalValue(0);
        } else {
            setPrincipalValue(val);
        }
    };

    return (
        <div className="col-md-4 col-12 col-lg-4">
            <div className="box">
                <div className={`box-top ${props.data.type === "gofolio" ? 'gofolio' : 'userDefined'}`}>
                    {props.data.name} - {props.data.type === "gofolio" ? "Gofolio Calculated" : "User Defined"}
                </div>
                {props.data.status === "Processing" ?
                    <>

                        <div className='box-inner-text'>
                            <div className="row">
                                <div className="col">
                                    <p>This portfolio is still processing, please wait just a few moments</p>
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col">
                                    <div className="spinner-grow" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-3">
                                <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                            </div>
                        </div>
                    </> : props.data.status === "Failed" ?
                        <>
                            <div className='box-inner-text'>
                                <p>There was an error retrieving this portfolio, please contact help@gofolio.com and provide this information: {props.data.id}</p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-3">
                                {props.data.canRefresh ? <button className="btn button-dark" onClick={async () => { await props.handleRefresh(props.data.id, props.src); }}>REFRESH PORTFOLIO</button> : null}
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-3">
                                <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                            </div>
                        </>
                        : props.data.status === "InsufficientData" ?
                            <>
                                <div className='box-inner-text'>
                                    <p>This portfolio cannot be processed as one or more included securities has not existed for at least 1 year.</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center my-3">
                                    <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                                </div>
                            </>
                            : props.data.isErr ?
                                <>
                                    <div className='box-inner-text'>
                                        <p>Error: {props.data.msg}</p>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center my-3">
                                        <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                                    </div>
                                </> : props.data.isLoading ?
                                    <>
                                        <div className='box-inner-text'>
                                            <div className="row">
                                                <div className="col">
                                                    <p>Please wait while we retrieve this portfolio's data...</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="spinner-grow" role="status">
                                                        <span className="visually-hidden"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center my-3">
                                            <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                                        </div>
                                    </> :
                                    <>
                                        <div className='box-inner-text mx-2'>
                                            <div className="d-flex mt-3 gap-1">
                                                {props.data.result?.beta ? <><InfoTip text="A Portfolio's 'lowest beta' is a measure of the portfolio's sensitivity to the S&P 500." placement="left"></InfoTip><button className={`btn button-one ${getResultKeyToRender(resultSelection, props) === "beta" ? "active" : ""}`} onClick={handleSetResultSelection} value="beta">LOWEST BETA</button></> : null}
                                                {props.data.result?.riskAdjustedReturn ? <><InfoTip text="A Portfolio's 'highest risk adjusted reward' is a measure that indicates a portfolios highest return per unit of risk taken." placement="top"></InfoTip><button className={`btn button-one ${getResultKeyToRender(resultSelection, props) === "riskAdjustedReturn" ? "active" : ""}`} onClick={handleSetResultSelection} value="riskAdjustedReturn">HIGHEST RISK ADJUSTED REWARD</button></> : null}
                                            </div>
                                            <div className="row mt-3 gx-1">
                                                <div className="col">
                                                    <button className={`btn button-one button-one-mini ${resultSelectionInterval === "1Y" ? "active" : ""}`} value="1Y" onClick={handleSetResultSelectionInterval}>1 YEAR</button>
                                                </div>
                                                {props.data.result[getResultKeyToRender(resultSelection, props)]["3Y"] ?
                                                    <div className="col">
                                                        <button className={`btn button-one button-one-mini ${resultSelectionInterval === "3Y" ? "active" : ""}`} value="3Y" onClick={handleSetResultSelectionInterval}>3 YEAR</button>
                                                    </div> : null}
                                                {props.data.result[getResultKeyToRender(resultSelection, props)]["5Y"] ?
                                                    <div className="col">
                                                        <button className={`btn button-one button-one-mini ${resultSelectionInterval === "5Y" ? "active" : ""}`} value="5Y" onClick={handleSetResultSelectionInterval}>5 YEAR</button>
                                                    </div> : null}
                                                {props.data.result[getResultKeyToRender(resultSelection, props)]["10Y"] ?
                                                    <div className="col">
                                                        <button className={`btn button-one button-one-mini ${resultSelectionInterval === "10Y" ? "active" : ""}`} value="10Y" onClick={handleSetResultSelectionInterval}>10 YEAR</button>
                                                    </div> : null}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p><InfoTip text="The total amount of money gained or lost on a particular investment or portfolio." placement="top"></InfoTip>
                                                        Portfolio Return: {getReturn(resultSelection, resultSelectionInterval, props)}</p>
                                                </div>
                                                <div className="col">
                                                    <p><InfoTip text="This is a measure of the volatility (systematic risk) of a security or portfolio compared to the S&P 500. The darker the red (and higher the number) means the more risk there is relative to the benchmark. The lighter the green (and lower the number) means less risk there is relative to the benchmark." placement="top"></InfoTip>
                                                        Portfolio Beta: {getBeta(resultSelection, resultSelectionInterval, props)}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="mt-2" style={{ lineHeight: '20px' }}>
                                                        <InfoTip text="The financial measure of investment risk and consistency in investment earnings. In other words, it measures the income variations in investments and the consistency of their returns. The darker the red (and higher the number) means the more risk there is relative to the benchmark. The lighter the green (and lower the number) means less risk there is relative to the benchmark." placement="top"></InfoTip>
                                                        Portfolio Standard Deviation: {getStandardDeviation(resultSelection, resultSelectionInterval, props)}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="chart text-center">
                                            <Chart toggle height={"300px"} chartData={props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].data} secMap={props.data.symbolNameMap} />
                                        </div>
                                        <div className="bottom text-center mx-5 px-3">
                                            <p>STARTING VALUE</p>
                                            <div style={{ maxWidth: '300px' }}>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ borderRadius: '0', fontWeight: 700 }}>$</span>
                                                    <input type="number" style={{ fontWeight: 700 }} className="form-control" onChange={handleSetPrincipalValue} defaultValue={principalValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mx-1">
                                            <div className="box-return d-flex my-3 mx-1" style={{ justifyContent: "center" }}>
                                                <PortfolioReturn></PortfolioReturn>
                                                <span className="right">${formatPrincipalValueResult(0.01 * principalValue * props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].expectedReturn)}</span>
                                            </div>
                                            <div className="box-return d-flex my-3" style={{ justifyContent: "center" }}>
                                                <PortfolioValue></PortfolioValue>
                                                <span className="right">${formatPrincipalValueResult((0.01 * principalValue * props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].expectedReturn) + principalValue)}</span>
                                            </div>
                                        </div>
                                        <div className="portfolio-date">
                                            Data as of: {formatDate(props.data.dataAsOfDate)}
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center my-3">
                                            <button className="btn button-dark" onClick={() => props.removeHook(props.data.id)}>REMOVE FROM PORTFOLIO COMPARE</button>
                                        </div>
                                    </>}
            </div>
        </div>
    )
}

export { CompareResult }