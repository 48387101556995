import React from 'react';
import logo from '../../dist/images/GOFOLIO_WHITE.svg'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";


const Navbar = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-lg navbar-light top-nav">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" style={{ border: 'none' }}>
                    <img src={logo} alt='' />
                </Link>
                <button
                    className="btn btn-dark d-inline-block d-lg-none ml-auto border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent1"
                    aria-controls="navbarSupportedContent1"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{ backgroundColor: '#026773' }}
                >
                    <i className="fa fa-bars" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                    <ul className="nav navbar-nav ms-auto">
                    {props.page === "blog" || props.page === "pricing" ? 
                        <li className="nav-item">
                            <button onClick={() => navigate('/')} className={props.page === "home" ? "nav-link active-page" : "nav-link"}>
                                Home
                            </button>
                        </li> : <></>}
                        <li className="nav-item">
                            <Link to="/pricing" className={location.pathname === "/pricing" ? "nav-link active-page" : "nav-link"}>
                                Pricing
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/blog" className={location.pathname === "/blog" ? "nav-link active-page" : "nav-link"}>
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/faq" className={location.pathname === "/faq" ? "nav-link active-page" : "nav-link"}>
                                FAQ
                            </Link>
                        </li>

                        <li className="nav-item my-auto">
                        {props.page !== "home" && props.page !== "blog" && props.page !== "pricing" ? 
                            <button
                                className="btn ms-2 button-one"
                                onClick={() => props.setPage('portfolioForm')}
                            >
                                CREATE NEW CALCULATION
                            </button> : <button
                                className="btn ms-2 button-one"
                                onClick={() => navigate('/login')}
                            >
                                LOGIN
                            </button>}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar