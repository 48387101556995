import React from 'react'
import Navbar from '../common/Navbar'
import TermsDisclosures from "../common/TermsDisclosures";


const FAQ = (props) => {
    return (
        <>
            <Navbar page={'faq'} />

            <div className='pricing-container mb-5 pb-5'>
                <div className="pricing-faq mt-2">
                    <h2 className='text-center text-white'>FREQUENTLY ASKED QUESTIONS</h2>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                What is The Gofolio?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Gofolio is a portfolio analytics tool that provides statistical insights into portfolios
                                generated by users including performance, beta, standard deviation, and much
                                more.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                Does Gofolio offer investment advice?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No, Gofolio is a research tool that provides insights into portfolios selected by the
                                user. Gofolio does not offer or solicit investment advice or recommend any
                                product/strategy/etc. Users are encouraged to seek the assistance of a financial
                                professional for any matters that may pertain to their specific needs or risk
                                tolerance. Gofolio is not a Registered Investment Advisor (RIA) or Broker-Dealer
                                (BD) and is not affiliated or registered with any financial industry regulatory
                                bodies. All investments involve risk and the potential for the loss of principal,
                                please consult with a financial professional regarding specific investment
                                questions.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                            >
                                What are beta and standard deviation?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Beta is a measure of volatility used relative to a benchmark, in the case of
                                Gofolio, that is the S&P500 ETF SPY. Standard Deviation is another statistical
                                measure that shows the variation of a portfolio from its average. A high beta is
                                more volatile than a low beta, and a high standard deviation means that the
                                returns vary further from the average return of the portfolio.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                            >
                                How do I interpret the Gofolio data?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                We at The Gofolio have done our best to make the data easy to interpret. The darker
                                the green means the lower the beta and/or standard deviation, while the darker
                                the red means the greater the beta or standard deviation, with a grey result
                                representing neutrality. Note that a high beta and/or a high standard deviation do
                                not represent the likelihood of greater gains, but provide insights as to volatility
                                and risk of user generated portfolios.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                            >
                                Will The Gofolio make me a portfolio?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No, Gofolio does not offer investment selection and does not offer investment
                                advice. Gofolio offers statistical insights into user generated portfolios.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSix"
                                aria-expanded="false"
                                aria-controls="flush-collapseSix"
                            >
                                How do I contact Gofolio?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSix"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Use the contact form below!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseSeven"
                                aria-expanded="false"
                                aria-controls="flush-collapseSeven"
                            >
                                What is the basis for the calculations used by Gofolio?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseSeven"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Gofolio utilizes the S&P500 ETF SPY as a benchmark, then calculates the
                                results based upon user inputs, or finds the lowest beta/highest risk-adjusted
                                allocation using a Monte Carlo analysis.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseEight"
                                aria-expanded="false"
                                aria-controls="flush-collapseEight"
                            >
                                Can I search options, bonds, or cryptocurrencies with Gofolio?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseEight"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No, at this time users can only search from listed stocks, ETFs and mutual funds.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseNine"
                                aria-expanded="false"
                                aria-controls="flush-collapseNine"
                            >
                                What are saved securities and saved portfolios?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseNine"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Gofolio allows users to save securities that they might be interested in now or in
                                the future, and model portfolios for future reference. Only user selected securities
                                and/or portfolios will appear in these sections.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTen"
                                aria-expanded="false"
                                aria-controls="flush-collapseTen"
                            >
                                Why am I limited to a minimum 5% allocation for a security and maximum 50%?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTen"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                While Gofolio does not offer investment advice, Gofolio does encourage
                                diversification. By implementing these boundaries Gofolio can ensure more well-
                                rounded allocations. Furthermore, when a user utilizes the Gofolio generation
                                function rather than the User-Defined function, Gofolio ranks the securities based
                                upon the desired outcome, and will maximize those securities that most meet the
                                criteria and minimize those than most negatively impact the desired outcome
                                when generating the allocation.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseEleven"
                                aria-expanded="false"
                                aria-controls="flush-collapseEleven"
                            >
                                Can Gofolio be used for investment services?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseEleven"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                No, Gofolio does not offer investment advice or investment services of any kind.
                                Gofolio provides statistical data on the past performance of a portfolio and does
                                not take in to account future earnings. Gofolio is to be used solely for
                                educational, informative and entertainment purposes as described in the Terms
                                of Service.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwelve"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwelve"
                            >
                                How do I reset my password?
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwelve"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                On The Gofolio login page, click the Forgot Password link.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="pricing-faq mt-2">
                        <h2 className='text-center text-white'>CONTACT US</h2>
                    </div>
                    <div className="row mt-3 text-center justify-content-center">
                        <div className="col-lg-6">
                            <input className="form-control" type="text" placeholder="Name" aria-label="Name" />
                            <div className="d-flex">
                                <input className="form-control me-2 my-3" type="email" placeholder="Email" aria-label="email" />
                                <input className="form-control ms-2 my-3" type="text" placeholder="Phone number" aria-label="number" />
                            </div>
                            <textarea className="form-control" placeholder='Your message' id="exampleFormControlTextarea1" rows="5"></textarea>
                            <button className='btn mt-3'>SEND</button>
                        </div>
                    </div>
                </div>
                <div className="pricing-faq mt-2">
                    <h2 className='text-center text-white'>TERMS & DISCLOSURES</h2>
                </div>
                <TermsDisclosures></TermsDisclosures>
            </div>
        </>
    )
}

export default FAQ