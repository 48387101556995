import icon from '../../dist/icons/GoFolio_MY_SECURITIES.svg';

const Box = (props) => {
    return (
        <div className="pt-2 portfolio-wrapper gofolio text-center">
            <div className="security-box">
                <div className="d-flex">
                    <div style={{ "flex": 1 }}>
                        {/* <input style={{ "float": "left" }} className="form-check-input" type="checkbox" value="" id="flexCheckDefault"></input> */}
                    </div>
                    <div>
                        <p className='security-text text-center'>{props.data.name}</p>
                    </div>
                    <div style={{ "flex": 1 }}></div>
                </div>
                <div className="row">
                    <div className='col'>
                        <img src={icon} width={50} alt='' />
                    </div>
                </div>
                <div className="d-flex">
                    <div style={{ "flex": 1 }}></div>
                    <div>
                        <a target="_blank" rel="noreferrer" href={`https://www.morningstar.com/search?query=${props.data.name}`}>
                            <button className="btn button-white">Morningstar</button>
                        </a>
                    </div>

                    <div className="mt-auto" style={{ "flex": 1 }}>
                        <button className="buttonX" style={{ "float": "right" }} onClick={() => props.removeSecurityHook(props.data, false)}>
                            <i className="pb-1 fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Box;