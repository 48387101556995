import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

// shout out to the MUI docs for this component: https://mui.com/material-ui/react-autocomplete/#virtualization

const LISTBOX_PADDING = 10; // px

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    return (
        <li {...dataSet[0]} nowrap="true" style={inlineStyle} className="virt-suggestions">
            {dataSet[1].symbol} - {dataSet[1].name}
        </li>
    );
}

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref,
) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 44 : 48;
    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const filterOptions = (options, state) => {
    // console.log(`filterOptions: options[0]: ${JSON.stringify(options[0])} ${typeof options[0]}`)
    if (!state.inputValue) {
        return [];
    }
    let input = state.inputValue.toUpperCase();
    let perfectMatch = null; 
    let tmpOptions = options.reduce((filtered, opt) => {
        if (opt.symbol === input) {
            perfectMatch = opt
        } else if (opt.symbol.startsWith(input)) {
            filtered.unshift(opt);
        } else if (opt.name.startsWith(input)) {
            filtered.push(opt);
        }
        return filtered;
    }, [])
    // if we found a perfect symbol match, put it at the top of the list
    if (perfectMatch) {
        tmpOptions.unshift(perfectMatch);
    }
    return tmpOptions;
};

export default function VirtualizedAutocomplete(props) {
    return (
        <Autocomplete
            id={props.id}
            sx={{ width: '100%' }}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={props.options}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            filterOptions={filterOptions}
            noOptionsText={'Enter a ticker or company'}
            getOptionLabel={(option) => {
                return option.symbol || '';
            }}
            renderInput={(params) => <TextField {...params} label={props.label} />}
            renderOption={(props, option, state) => [props, option, state.index]}
        />
    );
}