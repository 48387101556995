import { getPortfolioRun } from '../../api/api';

const wait = function (ms = 1000) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
};

const formatDate = (x) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let d = new Date(x);
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}

const getResultKeyToRender = (resultSelection, props) => {
    let key = resultSelection;
    if (!props.data.result[resultSelection]) {
        if (props.data.result['riskAdjustedReturn']) {
            key = 'riskAdjustedReturn';
        } else {
            key = 'userDefined';
        }
    }
    return key;
}
const getReturn = (resultSelection, resultSelectionInterval, props) => {
    let color = 'portfolio-result-black';
    let val = props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].expectedReturn
    if (val > 0) {
        color = 'portfolio-result-green'
    } else if (val < 0) {
        color = 'portfolio-result-red'
    } // else stays black
    return (
        <span className={`${color}`}>{val}%</span>
    )
}
const getBeta = (resultSelection, resultSelectionInterval, props) => {
    let val = props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].beta;
    if (!val && val !== 0) {
        return null;
    }
    let color = 'portfolio-result-green';
    if (val > 1.5) {
        color = 'portfolio-result-dark-red';
    } else if (val > 1.25) {
        color = 'portfolio-result-red';
    } else if (val > 0.75) {
        color = 'portfolio-result-black';
    } else if (val > 0.5) {
        color = 'portfolio-result-light-green';
    } // else stays green
    return (
        <span className={`${color}`}>{val}</span>
    )
}
const getStandardDeviation = (resultSelection, resultSelectionInterval, props) => {
    let val = props.data.result[getResultKeyToRender(resultSelection, props)][resultSelectionInterval].standardDeviation;
    if (!val) {
        return null;
    }
    let color = 'portfolio-result-black';
    // if (val > 1.5) {
    //     color = 'portfolio-result-dark-red';
    // } else if (val > 1.25) {
    //     color = 'portfolio-result-red';
    // } else if (val > 0.75) {
    //     color = 'portfolio-result-black';
    // } else if (val > 0.5) {
    //     color = 'portfolio-result-light-green';
    // } // else stays green
    return (
        <span className={`${color}`}>{val}%</span>
    )
}
const formatPrincipalValueResult = (x) => {
    // round to 2 decimal places
    let ret = Math.round((x + Number.EPSILON) * 100) / 100
    // add commas for readability
    var parts = ret.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const getPortfolioData = (id, portfolios, portfolioDataMap, handleUpdatePortfolioData, navigate) => {
    if (portfolioDataMap.hasOwnProperty(id)) {
      return new Promise((resolve, _) => {
        resolve(portfolioDataMap[id]);
      })
    } else {
      return getPortfolioRun(id).then((res) => {
        if (res.status === 202) {
          let ret = portfolios.find((item) => {
            return item.id === id
          })
          return ret
        } else if (res.status === 200) {
          handleUpdatePortfolioData(id, res.data)
          return res.data
        } else if (res.status === 403 || res.status === 404) {
          navigate("/login");
        } else {
          let ret = {
            id,
            isErr: true,
            msg: res.data.msg,
          }
          return ret
        }
      })
    }
  };

  const pollForPortfolio = async (id, portfolios, navigate) => {
    let portfolioPollAttempts = 0;
    while (portfolioPollAttempts < 20) {
      let res = await getPortfolioRun(id);
      if (res.status === 202) {
        portfolioPollAttempts++;
        await wait(5000);
        continue;
      } else if (res.status === 200) {
        return res.data;
      } else if (res.status === 403 || res.status === 404) {
        navigate("/login");
      } else {
        let ret = portfolios.find((item) => {
          return item.id === id
        })
        ret['isErr'] = true;
        ret['msg'] = res.data.msg;
        return ret;
      }
    }
    let ret = portfolios.find((item) => {
      return item.id === id
    })
    ret['isErr'] = true;
    ret['msg'] = `We were unable to retrieve this portfolio's data, please contact help@gofolio.com and provide this value: '${id} (max gets)' along with your login name.`;
    return ret;
  };

export { getPortfolioData, pollForPortfolio, wait, formatDate, getResultKeyToRender, getReturn, getBeta, getStandardDeviation, formatPrincipalValueResult };