import { useState, useEffect } from "react";
import logo from '../../dist/images/GOFOLIO_WHITE.svg'
import { userLogin } from '../../api/api';
import { useNavigate } from "react-router-dom";
import { keepAlive } from '../../api/api';

const Login = () => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [auth, setAuth] = useState('');
    const [msg, setMsg] = useState('');

    const navigate = useNavigate();

    const handleNameInput = (data) => {
        setName(data.target.value)
    }
    const handleAuthInput = (data) => {
        setAuth(data.target.value)
    }
    const doLogin = () => {
        if (name && auth) {
            setLoading(true);
            setMsg('');
            return userLogin(name, auth).then((res) => {
                if (res.status === 200) {
                    navigate("/app");
                } else {
                    setMsg('Email or password was incorrect');
                    setLoading(false);
                }
            })
        } else {
            setMsg('Please provide your email and password!');
            setLoading(false);
        }
    }

    useEffect(() => {
        keepAlive().then((res) => {
          if (res.status === 200) {
            navigate('/app');
          }
        });
      }, []);


    return (<div className="sign-in-bckgrnd">
        <div className="sign-in-foregrnd">
            <div className="text-center">
                <img src={logo} width="228.996px" height="93.9961" alt='' />
            </div>

            <div className="sign-in-form">
                <h1 className="sign-in-header text-center">Welcome Back!</h1>
                <p className="sign-in-ask text-center">Please login to your account.</p>
                <p className="error-msg text-center">{msg}</p>
                <form className="text-center px-5 pt-4">
                    <div className="input-group mb-3 text-center">
                        <input type="text" className="form-control" placeholder="Email" onChange={handleNameInput} />
                    </div>
                    <div className="input-group mb-3 text-center">
                        <input type="password" className="form-control" placeholder="Password" onChange={handleAuthInput} />
                    </div>

                    {/* <div className="row mt-3 sign-in-label text-center">
                        <div className="col">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" value="" id="sign-in-remember-me" />
                                <label className="form-check-label" htmlFor="sign-in-remember-me">
                                    Remember Me
                                </label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group mb-3">
                                <a href="http://google.com">Forgot Password</a>
                            </div>
                        </div>
                    </div> */}

                </form>
                <div className="row text-center">
                    <div className="col mt-3">
                        <button className="btn button-dark" onClick={doLogin}>{ loading ? <span className="spinner-grow spinner-grow-sm" style={{color: '#026773'}}></span> : 'LOGIN' }</button>
                    </div>

                    <div className="col mt-3">
                        <button className="btn button-light">SIGN UP</button>
                    </div>
                </div>
                <div className="row pt-5 text-center">
                    <div className="col">
                        <p>For help, please contact:</p>
                        <p>help@thegofolio.com</p>
                    </div>
                </div>
                <div className="row pt-4 text-center">
                    <div className="col">
                        <p>Connect with us on social media</p>
                    </div>
                </div>
                <div className="text-center">
                    <a href="http://google.com">
                        <img
                            src="/facebook_icon_navy.svg"
                            width="50px"
                            height="50px"
                            alt="Facebook"
                        />
                    </a>
                    <a href="http://google.com">
                        <img
                            src="/ig_icon_navy.svg"
                            width="50px"
                            height="50px"
                            alt="Instagram"
                            style={{ marginLeft: '20px' }}
                        />
                    </a>
                    <div className="diclaimer-container pt-3">
                        <div>
                            <a href="http://google.com">Terms of Use</a>
                        </div>
                        <div>|</div>
                        <div>
                            <a href="http://google.com">Privacy Policy</a>
                        </div>
                        <div>|</div>
                        <div>
                            <a href="http://google.com">Disclosures</a>
                        </div>
                    </div>
                    <div className="pt-3">
                        <p className="copyright-text">&copy; 2023 Gofolio. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Login;