
const _examplePortfolio = { "id": -1, "name": "Example (not real) Portfolio", "type": "gofolio", "topExpectedReturn": 99.99, "status": "Complete" }
const _examplePortfolioData = { "id": -1, "name": "Example (not real) Portfolio", "type": "gofolio", "status": "Complete", "dataAsOfDate": "2023-06-20T00:00:00Z", "canRefresh": false, "result": { "beta": { "10Y": { "expectedReturn": 81.36, "beta": 71.51, "standardDeviation": 83.95, "data": { "AAA": 6, "NEE": 6, "ABCSX": 38, "ABHYX": 25, "QTWO": 12, "AMRS": 13 } }, "1Y": { "expectedReturn": 10.65, "beta": 69.03, "standardDeviation": 68.26, "data": { "AAA": 12, "NEE": 21, "ABCSX": 6, "ABHYX": 10, "QTWO": 45, "AMRS": 6 } }, "3Y": { "expectedReturn": 86.59, "beta": 87.93, "standardDeviation": 64.69, "data": { "AAA": 17, "NEE": 6, "ABCSX": 24, "ABHYX": 20, "QTWO": 22, "AMRS": 11 } }, "5Y": { "expectedReturn": 52.79, "beta": 56.59, "standardDeviation": 58.76, "data": { "AAA": 6, "NEE": 41, "ABCSX": 7, "ABHYX": 12, "QTWO": 10, "AMRS": 24 } } }, "riskAdjustedReturn": { "10Y": { "expectedReturn": 72.56, "beta": 65.14, "standardDeviation": 97.48, "data": { "AAA": 6, "NEE": 12, "ABCSX": 9, "ABHYX": 6, "QTWO": 48, "AMRS": 19 } }, "1Y": { "expectedReturn": 12.17, "beta": 60.73, "standardDeviation": 43.46, "data": { "AAA": 38, "NEE": 6, "ABCSX": 24, "ABHYX": 10, "QTWO": 15, "AMRS": 7 } }, "3Y": { "expectedReturn": 41.5, "beta": 75.12, "standardDeviation": 31.13, "data": { "AAA": 7, "NEE": 20, "ABCSX": 18, "ABHYX": 20, "QTWO": 29, "AMRS": 6 } }, "5Y": { "expectedReturn": 70.78, "beta": 74.24, "standardDeviation": 55.3, "data": { "AAA": 19, "NEE": 6, "ABCSX": 6, "ABHYX": 14, "QTWO": 48, "AMRS": 7 } } } }, "symbolNameMap": { "AAA": "LISTED FUNDS TRUST - AAF FIRST PRIORITY CLO BOND ETF", "NEE": "NEXTERA ENERGY INC", "ABCSX": "AB DISCOVERY VALUE FUND CLASS C ", "ABHYX": "HIGH-YIELD MUNICIPAL FUND INVESTOR CLASS", "QTWO": "Q2 HOLDINGS", "AMRS": "AMYRIS INC", } }

const _newPortfolioShowSecurities = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: false,
    8: false
  };

  const _newPortfolioSecurityIds = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0
  };

  const _newPortfolioWeights = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0
  }
export { _examplePortfolio, _examplePortfolioData, _newPortfolioShowSecurities, _newPortfolioSecurityIds, _newPortfolioWeights }