import React from 'react';
import { Chart as ChartG } from 'react-google-charts';

const chartColors = ['#79d5d5', '#3ca6a6', '#026773', '#abe8e8', '#a3efdb', '#A7C9D3', '#0D98BA', '#AEE7F8']
const options = {
    colors: chartColors,
    legend: { position: 'left', alignment: 'center' },
    chartArea: {
        'height': '75%',
        'width': '75%',
    },
    responsive: true,
    maintainAspectRatio: true,
    tooltip: {
        text: 'percentage',
        // isHtml: true // to enable true html
    }
};

const Chart = (props) => {
    const { chartData } = props;
    var data = [['Ticker', 'Percent', { 'type': 'string', 'role': 'tooltip', 'p': {'html': true} }]]
    for (const [key, value] of Object.entries(chartData)) {
        data.push([key, value, `${props.secMap[key]} (${key})`]);
    }
    return (
        <ChartG
            chartType="PieChart"
            data={data}
            width={"100%"}
            height={props.height}
            options={options}
        />
    );
}

const LoadingChart = () => {
    const chartData = { '1': 8, '2': 10, '3': 8, '4': 15, '5': 5, '6': 10, '7': 15, '8': 15 };
    var data = [['Ticker', 'Percent']]
    for (const [key, value] of Object.entries(chartData)) {
        data.push([key, value]);
    }
    return (
        <ChartG
            chartType="PieChart"
            data={data}
            width={"100%"}
            height={"100px"}
            options={{
                colors: chartColors,
                legend: 'none'
            }}
        />
    );
}

export { Chart, LoadingChart };
