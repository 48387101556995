import React from "react"
import card from '../../dist/icons/GoFolio_PORTFOLIO_CARD_ICON.svg'

const TopPerformer = (props) => {
  const portfolioName = props.data.name.length > 34 ? props.data.name.substring(0, 34) + '...' : props.data.name
  return (
    <div key={props.data.id} data-testid={`top-performer-id-${props.data.id}`} className={`p-3 top-performer-wrapper ${props.data.type === "userDefined" ? 'userDefined' : 'gofolio'}`}>
      <div className="top-performer-box">
        <div className="d-flex">
          <div className='d-flex'>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={props.topPortfoliosSelected.some(e => e?.id === props.data.id)} disabled={props.topPortfoliosSelected.length >= 3 && !props.topPortfoliosSelected.some(e => e?.id === props.data.id)} onChange={() => {props.handleSelectTopPortfolio(props.data)}}></input>
            </div>
            <p className='portfolio-top-performer-text'>{portfolioName}</p>
          </div>
          <img src={card} width={54} alt='' />
        </div>
        <h2 className='percentage'>{props.data.topExpectedReturn}%</h2>
        <p className='portfolio-top-performer-text-type'>{props.data.type === "userDefined" ? "User Defined" : "Gofolio Calculated"}</p>
        <span className="d-flex mt-2">
          <button className="buttonDetails" onClick={async () => { await props.portfolioClickHook(props.data.id) }}>Details</button>
        </span>
      </div>
    </div>
  )
}

const TopPerformerList = (props) => {
  return (
    <>
      <div className="portfolio-container">
        <div className="row" data-testid="top-performers-row">
          {props.portfolios.map((portfolio) => {
            return <TopPerformer key={portfolio.id} data={portfolio} topPortfoliosSelected={props.topPortfoliosSelected} handleSelectTopPortfolio={props.handleSelectTopPortfolio} portfolioClickHook={props.portfolioClickHook}></TopPerformer>
          })}
        </div>
      </div>
    </>

  )
}

export { TopPerformer, TopPerformerList }