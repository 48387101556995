import React from "react"
import Box from "./Box";

const ListFiltered = (props) => {
  return (
    <>
      <div className="top-bar">
        <ul>
          <li className="ms-auto">
          </li>
        </ul>
      </div>
      <List data={props.data} removeSecurityHook={props.removeSecurityHook}></List>
    </>
  )
}

const List = (props) => {
  return (
    <>
      <div className="portfolio-container">
        <div className="row">
          {Object.keys(props.data).map((secId) => {
            return <Box key={secId} data={{ 'id': secId, 'name': props.data[secId] }} removeSecurityHook={props.removeSecurityHook}></Box>
          })}
        </div>
      </div>
    </>

  )
}

export { ListFiltered, List }