import React from 'react';

function TermsDisclosures() {
  return (
    <div className="accordion accordion-flush" id="accordionFlushExample">
    <div className="accordion-item">
        <h2 className="accordion-header">
            <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
            >
                Terms and Disclosures
            </button>
        </h2>
        <div
            id="flush-collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionFlushExample"
        >
            <div className="accordion-body">
                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
            </div>
        </div>
    </div>
</div>
  );
}

export default TermsDisclosures;
