import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const InfoTip = (props) => {
    let placement = props.placement === undefined ? 'bottom' : props.placement;
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Popover id="popover-basic">
                    <Popover.Body>
                        {props.text}
                    </Popover.Body>
                </Popover>
            }
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" stroke="#a3d6cf" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </OverlayTrigger>
    );
}

const PortfolioValue = () => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Popover id="popover-basic">
                    <Popover.Body>
                        This is the total amount of money including gains and losses a particular portfolio.
                    </Popover.Body>
                </Popover>
            }
        >
            <span className="left">PORTFOLIO VALUE</span>
        </OverlayTrigger>
    );
}

const PortfolioReturn = () => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Popover id="popover-basic">
                    <Popover.Body>
                        This is the total amount of money gained or lost on a particular portfolio.
                    </Popover.Body>
                </Popover>
            }
        >
            <span className="left">PORTFOLIO RETURN</span>
        </OverlayTrigger>
    );
}
export { InfoTip, PortfolioValue, PortfolioReturn };
