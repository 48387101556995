import React from 'react';
import Navbar from '../common/Navbar';

const Pricing = () => {
    return (
        <>
            <Navbar page={'pricing'} />
            <div className='pricing-container mb-5 pb-5'>
                <h1 className='text-center text-uppercase'>A Simple pricing model</h1>
                <p className='mx-4 text-center' style={{ color: '#012e40' }}>Some software solutions will charge thousands of dollars per year!</p>
                <p className='mx-4 text-center' style={{ color: '#012e40' }}>Our goal at Gofolio is to provide these insights and solutions at the lowest sustainable cost that we can to provide data driven analytics for our customers.</p>
                <div className="row my-4">
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="pricing-inner-box text-center">
                            <h2 className='mt-3'>BASIC</h2>
                            <h1>
                                $5
                            </h1>
                            <p>PER USER, MONTH</p>
                            <p className='mx-5 px-4 mt-4 text'>Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui </p>
                            <button className='btn mt-2'>FREE TRIAL</button>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="pricing-inner-box text-center" style={{ backgroundColor: '#3ca6a6' }}>
                            <h2 className='mt-3'>INTERMEDIATE</h2>
                            <h1>
                                $10
                            </h1>
                            <p>PER USER, MONTH</p>
                            <p className='mx-5 px-4 mt-4'>Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui </p>
                            <button className='btn mt-2'>FREE TRIAL</button>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12">
                        <div className="pricing-inner-box text-center" style={{ backgroundColor: '#026773' }}>
                            <h2 className='mt-3'>ADVISOR</h2>
                            <h1>
                                $10
                            </h1>
                            <p>PER USER, MONTH</p>
                            <p className='mx-5 px-4 mt-4'>Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui </p>
                            <button className='btn mt-2'>FREE TRIAL</button>
                        </div>
                    </div>
                </div>
                <div className="pricing-faq mt-2">
                    <h2 className='text-center text-white'>F.A.Q.</h2>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                Question 1
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                Question 2
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                            >
                                Question 3
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour"
                                aria-expanded="false"
                                aria-controls="flush-collapseFour"
                            >
                                Question 4
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFour"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFive"
                                aria-expanded="false"
                                aria-controls="flush-collapseFive"
                            >
                                Question 5
                            </button>
                        </h2>
                        <div
                            id="flush-collapseFive"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">
                                Rum quas dus ent aut ut aliquam restiam escia ipsa nobit aut quis ad mod qui conse nusciist eaque nobitec uptaerferit venitatur aperferovid quatur, oditiorum nones comnisq uidelenis autem quatia sa cor sae dolorum non reiur? Us, sus. Pidit offic to temperem labo. Igenihiliqui inctatibus volorerum volectorpor sit identur, tem aribus. Repuditae pro consequosa dolorum re es modionet liquidunt aspere doloreperio. Fic te eaquunt eariatis est endam, ute et elesequo quid ma nam disquidel illuptatendi vel et eaque por assit voluptatur? Harum fugit dendaepedis am essum est explitaecus vera nimaximus dessit qui occuptatiae coressunt utemqui vellupi deriostis exernam latempor recto minveni magniendae nullecus evelluptas ut liqui andi res nemque cones acea nullab idessunt.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-box">
                    <div className="pricing-faq mt-2">
                        <h2 className='text-center text-white'>Contact Us</h2>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-8 mx-auto">
                            <input className="form-control" type="text" placeholder="Name" aria-label="Name" />
                            <div className="d-flex">
                                <input className="form-control me-2 my-3" type="email" placeholder="Email" aria-label="email" />
                                <input className="form-control ms-2 my-3" type="text" placeholder="Phone number" aria-label="number" />
                            </div>
                            <textarea className="form-control" placeholder='Your message' id="exampleFormControlTextarea1" rows="5"></textarea>
                            <button className='btn mt-3'>SEND</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing