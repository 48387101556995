import axios from 'axios';

// dont throw errors on 400s, 500s
axios.defaults.validateStatus = function () {
    return true;
};
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.defaults.withCredentials = true;

export async function getUserSecurities() {
    return await axios.get('/app/security/all', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function getAllSecurities() {
    return await axios.get('/app/securities', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function updateSecurity(id) {
    return await axios.put(`/app/security/${id}`, null, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function removeSecurity(id) {
    return await axios.delete(`/app/security/${id}`, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function getPortfolioRun(id) {
    return await axios.get(`/app/portfolio/${id}`, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function refreshPortfolioRun(id) {
    return await axios.get(`/app/portfolio/${id}/refresh`, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function createPortfolioRun(data) {
    return await axios.post('/app/portfolio', data, {
        validateStatus: () => true,
    })
        .then((res) => {
            return { status: res.status, data: res.data };
        });
}

export async function getPortfolios() {
    return await axios.get('/app/portfolio/all', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function getTopPortfolios() {
    return await axios.get('/app/portfolio/top', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function getLatestPortfolio() {
    return await axios.get('/app/portfolio/latest', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function removePortfolio(id) {
    // handle deleting example portfolio
    if (id === -1) {
        return {
            status: 200,
        }
    }
    return await axios.delete(`/app/portfolio/${id}`, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function getUserData() {
    return await axios.get('/app/user', {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function updateUserData(data) {
    return await axios.put('/app/user', data, {
        validateStatus: () => true,
    }).then((res) => {
        return { status: res.status, data: res.data };
    });
}

export async function keepAlive(refresh = false) {
    let res;
    if (refresh) {
        res = await axios.post('/app/elo', {
            validateStatus: () => true,
        });
    } else {
        res = await axios.get('/app/elo', {
            validateStatus: () => true,
        });
    }
    return { status: res.status, data: res.data };
}

export async function userLogin(name, auth) {
    return await axios.post('/app/login', { name, auth }, {
        validateStatus: () => true,
    })
        .then((res) => {
            if (res.status === 200) {
                window.localStorage.setItem('gToke', JSON.stringify(res.data.token));
                return { status: res.status, data: res.data };
            }
            return { status: res.status, data: res.data };
        });
}

export async function userLogoff() {
    return await axios.get('/app/logoff', {
        validateStatus: () => true,
    })
        .then((res) => {
            return { status: res.status, data: res.data };
        });
}